import { Injectable } from '@angular/core';
import {
  HttpParams, HttpClient, HttpEvent, HttpRequest, HttpParameterCodec, HttpHeaders,
} from '@angular/common/http';
import { BBG } from '../../models/server-dto';
import { getEndpointPath } from '../../utils/server-path';
import { of, Observable } from 'rxjs';
import { HttpRequestOptions } from 'nl';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {

  constructor(private httpClient: HttpClient) { }

  public get<T = any>(endpoint: string, options?: HttpRequestOptions) {
    // Convert params to browser standard
    if (options && options.params) {
      const paramsKey = options.params.keys();
      const paramsObj: { [paramName: string]: string[] } = {};
      paramsKey.forEach(key => {
        paramsObj[key] = options.params.getAll(key);
      })

      options.params = new HttpParams({
        encoder: new HttpParameterUriEncoder(),
        fromObject: paramsObj
      });
    }

    return this.httpClient.get<BBG.Transfer.ResponseDto<T>>(getEndpointPath(endpoint), options);
  }

  public post<T = any>(endpoint: string, body: any, options?: HttpRequestOptions) {
    return this.httpClient.post<BBG.Transfer.ResponseDto<T>>(getEndpointPath(endpoint), body, options);
  }

  public put<T = any>(endpoint: string, body: any, options?: HttpRequestOptions) {
    return this.httpClient.put<BBG.Transfer.ResponseDto<T>>(getEndpointPath(endpoint), body, options);
  }

  public delete<T = any>(endpoint: string, options?: HttpRequestOptions) {
    return this.httpClient.delete<BBG.Transfer.ResponseDto<T>>(getEndpointPath(endpoint), options);
  }

  public patch<T = any>(endpoint: string, body: any, options?: HttpRequestOptions) {
    return this.httpClient.patch<BBG.Transfer.ResponseDto<T>>(getEndpointPath(endpoint), body, options);
  }

  public getLocalFiles(path: string) {
    return this.httpClient.get(path);
  }

  public getFileURL(path: string) {
    return of(getEndpointPath(path));
  }

  public uploadFile<T = any>(
    endpoint: string,
    formData: FormData,
    options?: HttpRequestOptions
  ): Observable<HttpEvent<BBG.Transfer.ResponseDto<T>>> {
    const req = new HttpRequest(
      'POST',
      getEndpointPath(endpoint),
      formData,
      {
        ...options,
        reportProgress: true
      },
    );

    return this.httpClient.request<BBG.Transfer.ResponseDto<T>>(req);
  }
}



/**
 * HttpParameterCodec for https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
 */
class HttpParameterUriEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
