/* tslint:disable:no-empty-interface indent */
export namespace BBG.Transfer {
	export interface AuthTokensDto  {
		jwtToken: BBG.Transfer.Token.TokenDto;
	}

	export interface ErrorDto  {
		code: BBG.Core.ErrorCode;
		codeName: string;
	}

	export interface ListQuery  {
		orderBy: BBG.Transfer.OrderByQuery;
		pageIndex: number;
		pageSize: number;
		searchBy: string[];
	}

	export interface OrderByQuery  {
		ascending?: boolean;
		field: string;
	}

	export interface PagedListDto<T>  {
		count: number;
		items: T[];
		orderBy: BBG.Transfer.OrderByQuery;
		pageIndex: number;
		pageSize: number;
		searchBy: string[];
	}

	export interface PagedListPriceRangeDto<T> extends BBG.Transfer.PagedListDto<T> {
		priceMax: number;
		priceMin: number;
	}

	export interface ResponseDto<T>  {
		data: T;
		error: BBG.Transfer.ErrorDto;
	}

	export interface TimeRangeListQuery extends BBG.Transfer.ListQuery {
		endDate?: string;
		startDate?: string;
	}

}
export namespace BBG.Transfer.Token {
	export interface TokenDto  {
		expiration: string;
		token: string;
	}

}
export namespace BBG.Core {
	export enum BookingStatus {
		Cancelled = 5,
		Confirmed = 2,
		Finished = 4,
		New = 1,
		Paid = 3
	}
	export enum CultureName {
		De = 3,
		En = 1,
		Fr = 2,
		Pl = 0
	}
	export enum ErrorCode {
		AccessDenied = 4,
		BookingDoesNotHaveInvoiceGenerated = 75,
		BookingHistoryNotFound = 48,
		BookingNotFound = 36,
		BookingSpecNotFound = 38,
		BookingStatusForbidsPaymentsChanges = 64,
		CannotChangePaymentTypeFromOrToPayUPayment = 65,
		CannotCreateInvoiceForEmptyBookingSpecs = 78,
		CannotCreateOrUpdateExternalInvoiceForGivenBooking = 79,
		CannotCreatePayUOrder = 58,
		CannotCreatePayUPayment = 59,
		CannotDeleteAdmin = 61,
		CannotDeleteNonCancelledBooking = 68,
		CannotDeletePayUPayment = 69,
		CannotDeleteUserWithBookings = 60,
		CannotUpdateValueForPayUPayment = 46,
		CultureNotSupported = 82,
		EmailExists = 50,
		EmailNotConfirmed = 18,
		EmptyYachtBooking = 37,
		EntityNotFound = 6,
		ExternalEmailNotConfirmed = 16,
		ExternalInvoiceIdNotFound = 77,
		ExternalProviderCannotBeDisconnected = 19,
		ExternalProviderNotFound = 13,
		FileAlreadyExists = 22,
		FileNotFound = 23,
		FileSizeTooBig = 20,
		FileTypeNotSupported = 21,
		GpsApiResponseError = 71,
		GpsDataNotFound = 73,
		GpsIdNotFound = 72,
		IdNotFound = 27,
		ImageAlreadyAppendedToAnotherYacht = 42,
		ImageNotFound = 30,
		IncompleteAddress = 54,
		IncompleteCompanyData = 55,
		IncompleteUserData = 56,
		IncorrectCredentials = 8,
		InvalidBackgroundImageId = 44,
		InvalidBookingRange = 80,
		InvalidBookingStatus = 67,
		InvalidBookingStatusChangeRequest = 49,
		InvalidCapacityRange = 35,
		InvalidChosenImageIds = 43,
		InvalidConfirmAccountToken = 11,
		InvalidCredentials = 7,
		InvalidHeathenProductionYear = 29,
		InvalidJwtToken = 5,
		InvalidPaymentType = 66,
		InvalidPrice = 33,
		InvalidPriceType = 31,
		InvalidProductionYear = 28,
		InvalidRestartPasswordToken = 12,
		InvalidTimeRange = 26,
		InvalidTimeRangeForChosenPriceType = 74,
		InvalidTimeRangeForYachtPrice = 32,
		InvalidYachtParameterCode = 70,
		InvalidYachtPriceType = 81,
		InvoiceApiResponseError = 76,
		InvoiceForGivenBookingAlreadyExists = 57,
		InvoiceNotFound = 53,
		MailAlreadySent = 51,
		OperationRequiresUserContext = 15,
		OrderByFieldNotFound = 2,
		PasswordAlreadySet = 17,
		PasswordMismatch = 47,
		PaymentCannotHaveNegativeOrZeroValue = 62,
		PaymentNotFound = 45,
		PaymentTooHighForBooking = 63,
		TooBigPageSize = 1,
		Unauthorized = 39,
		UnexpectedError = 3,
		UnspecifiedValue = 41,
		UserLockedOut = 52,
		UserNotConnectedWithProvider = 14,
		UserNotFound = 9,
		ValidationFailed = 0,
		YachtClassNotFound = 34,
		YachtNotFound = 10,
		YachtPriceNotFound = 25,
		YachtPriceNotFoundInTimeRange = 24,
		YachtUnavailableInGivenTimeRange = 40
	}
	export enum ExternalProviderType {
		Facebook = 0,
		Google = 1
	}
	export enum ImageSizeType {
		M = 2,
		S = 1,
		XL = 3
	}
	export enum ParameterCode {
		CabinHeight = 6,
		CentreboardType_en = 10,
		CentreboardType_pl = 8,
		Engine = 3,
		LockedBedrooms = 2,
		MaxImmersion = 4,
		MinImmersion = 5,
		ProductionYear = 0,
		Rooms = 1,
		RudderType_en = 11,
		RudderType_pl = 9,
		SailSurface = 7
	}
	export enum PaymentStatus {
		Completed = 2,
		Created = 3,
		New = 0,
		Rejected = 1
	}
	export enum PaymentType {
		BankTransfer = 3,
		Cash = 2,
		PayU = 1
	}
	export enum YachtClassType {
		Motor = 0,
		Sail = 1
	}
	export enum YachtDayState {
		Available = 0,
		Booked = 1,
		Unavailable = 2
	}
	export enum YachtPriceType {
		Daily = 0,
		Range = 1,
		Weekly = 2
	}
	export interface ExternalUserInfo  {
		displayName: string;
		email: string;
		emailConfirmed: boolean;
		externalUserId: string;
		firstName: string;
		lastName: string;
	}

	export interface PayUPaymentConfiguration extends PayUPayment.Config.PayUConfiguration {
		continueUrl: string;
		notificationUrl: string;
	}

}
export namespace BBG.Transfer.YachtPrice {
	export interface YachtPriceDto  {
		dateFrom: string;
		dateTo: string;
		id: number;
		internalInfo: string;
		price: number;
		type: BBG.Core.YachtPriceType;
		yachtId: number;
		yachtName: string;
	}

}
export namespace BBG.Transfer.YachtPrice.Query {
	export interface CalculateYachtPriceQuery  {
		endDate: string;
		startDate: string;
	}

	export interface ListYachtPriceQuery extends BBG.Transfer.TimeRangeListQuery {
		yachtIds: number[];
	}

}
export namespace BBG.Transfer.YachtPrice.Command {
	export interface CreateYachtPriceCommand  {
		dateFrom: string;
		dateTo: string;
		internalInfo: string;
		price: number;
		type: BBG.Core.YachtPriceType;
		yachtId: number;
	}

	export interface UpdateYachtPriceCommand extends BBG.Transfer.YachtPrice.Command.CreateYachtPriceCommand {
	}

}
export namespace BBG.Transfer.YachtImage.Command {
	export interface UploadYachtImagesCommand  {
		images: Array<any>;
	}

}
export namespace BBG.Transfer.YachtClass {
	export interface YachtClassDto  {
		capacity: number;
		description_en: string;
		description_pl: string;
		id: number;
		length: number;
		name: string;
		type: BBG.Core.YachtClassType;
		width: number;
	}

}
export namespace BBG.Transfer.YachtClass.Query {
	export interface ListYachtClassQuery extends BBG.Transfer.ListQuery {
		classType?: BBG.Core.YachtClassType;
	}

}
export namespace BBG.Transfer.YachtClass.Command {
	export interface CreateYachtClassCommand  {
		capacity: number;
		description_en: string;
		description_pl: string;
		length: number;
		name: string;
		type: BBG.Core.YachtClassType;
		width: number;
	}

	export interface UpdateYachtClassCommand extends BBG.Transfer.YachtClass.Command.CreateYachtClassCommand {
	}

}
export namespace BBG.Transfer.Yacht {
	export interface YachtAdminDto extends BBG.Transfer.Yacht.YachtDto {
		availableFrom?: string;
		availableTo?: string;
		internalInfo: string;
		price?: number;
	}

	export interface YachtDayDto  {
		day: string;
		isMorningAvailableState: boolean;
		price?: number;
		priceId?: number;
		priceType?: BBG.Core.YachtPriceType;
		state: BBG.Core.YachtDayState;
		yachtId: number;
	}

	export interface YachtDto  {
		backgroundImageId?: number;
		capacity: number;
		chosenImageIds: number[];
		classId: number;
		className: string;
		classType: BBG.Core.YachtClassType;
		description_en: string;
		description_pl: string;
		descriptionIntro_en: string;
		descriptionIntro_pl: string;
		gpsId?: number;
		id: number;
		imageIds: number[];
		isRecommended: boolean;
		length: number;
		name: string;
		parameters: BBG.Transfer.Parameter.YachtParameterDto[];
		priceMax?: number;
		priceMin?: number;
		width: number;
	}

}
export namespace BBG.Transfer.Parameter {
	export interface ParameterDto  {
		code: string;
		unit: string;
	}

	export interface YachtParameterDto  {
		code: string;
		unit: string;
		value: string;
		yachtId: number;
	}

}
export namespace BBG.Transfer.Yacht.Query {
	export interface CalendarYachtQuery  {
		endDate: string;
		startDate: string;
	}

	export interface ListYachtAdminQuery extends BBG.Transfer.Yacht.Query.ListYachtQuery {
		filterOutBookingId?: number;
		filterOutYachtIds: number[];
	}

	export interface ListYachtQuery extends BBG.Transfer.TimeRangeListQuery {
		capacityMin?: number;
		classes: number[];
		classType?: BBG.Core.YachtClassType;
		onlyFullyAvailable: boolean;
		priceMax?: number;
		priceMin?: number;
	}

}
export namespace BBG.Transfer.Yacht.Command {
	export interface CreateYachtCommand  {
		backgroundImageId?: number;
		chosenImageIds: Array<number>;
		description_en: string;
		description_pl: string;
		descriptionIntro_en: string;
		descriptionIntro_pl: string;
		gpsId?: number;
		imageIds: Array<number>;
		internalInfo: string;
		isRecommended: boolean;
		name: string;
		parameters: Array<BBG.Transfer.Yacht.Command.YachtParameterInYachtCommand>;
		yachtClassId: number;
	}

	export interface UpdateYachtCommand extends BBG.Transfer.Yacht.Command.CreateYachtCommand {
	}

	export interface YachtParameterInYachtCommand  {
		code: string;
		value: string;
	}

}
export namespace BBG.Transfer.User {
	export interface AddressBasicDto  {
		city: string;
		country: string;
		postalCode: string;
		street: string;
	}

	export interface AddressDto extends BBG.Transfer.User.AddressBasicDto {
		id: number;
	}

	export interface CompanyBasicDto  {
		address: BBG.Transfer.User.AddressBasicDto;
		name: string;
		nip: number;
	}

	export interface CompanyDto extends BBG.Transfer.User.CompanyBasicDto {
		id: number;
	}

	export interface UserBasicDto  {
		email: string;
		firstName: string;
		id: string;
		isAdmin: boolean;
		lastName: string;
	}

	export interface UserDto extends BBG.Transfer.User.UserBasicDto {
		address: BBG.Transfer.User.AddressDto;
		company: BBG.Transfer.User.CompanyDto;
		culture: BBG.Core.CultureName;
		isPasswordSet: boolean;
		nip?: number;
		telephone?: number;
	}

}
export namespace BBG.Transfer.User.Query {
	export interface ListUserQuery extends BBG.Transfer.ListQuery {
	}

}
export namespace BBG.Transfer.User.Command {
	export interface CreateUserCommand  {
		address: BBG.Transfer.User.AddressBasicDto;
		company: BBG.Transfer.User.CompanyBasicDto;
		culture: BBG.Core.CultureName;
		email: string;
		firstName: string;
		isAdmin: boolean;
		lastName: string;
		nip?: number;
		telephone?: number;
	}

	export interface UpdateUserCommand extends BBG.Transfer.Account.Command.UpdateProfileCommand {
		isAdmin: boolean;
	}

}
export namespace BBG.Transfer.Account.Command {
	export interface ChangePasswordCommand  {
		newPassword: string;
		password: string;
	}

	export interface ConfirmCommand  {
		confirmToken: string;
		userId: string;
	}

	export interface DisconnectExternalProviderCommand  {
		provider: BBG.Core.ExternalProviderType;
	}

	export interface RegisterByExternalProviderCommand  {
		code: string;
		provider: BBG.Core.ExternalProviderType;
	}

	export interface RegisterCommand  {
		email: string;
		firstName: string;
		lastName: string;
		password: string;
	}

	export interface RestartPasswordCommand  {
		newPassword: string;
		restartToken: string;
		userId: string;
	}

	export interface RestartPasswordTokenCommand extends BBG.Transfer.Account.Command.SendEmailCommand {
	}

	export interface SendAccountCreatedCommand extends BBG.Transfer.Account.Command.SendEmailCommand {
	}

	export interface SendConfirmationTokenCommand extends BBG.Transfer.Account.Command.SendEmailCommand {
	}

	export interface SendEmailCommand  {
		userEmail: string;
	}

	export interface SetPasswordCommand  {
		password: string;
	}

	export interface SetPasswordTokenCommand extends BBG.Transfer.Account.Command.SendEmailCommand {
	}

	export interface UpdateProfileCommand  {
		address: BBG.Transfer.User.AddressBasicDto;
		company: BBG.Transfer.User.CompanyBasicDto;
		culture: BBG.Core.CultureName;
		firstName: string;
		lastName: string;
		nip?: number;
		telephone?: number;
	}

}
export namespace BBG.Transfer.SendMailJob {
	export interface SendEmailJobDto  {
		body: string;
		creationDate: string;
		id: number;
		mailAddressTo: string;
		sentDate?: string;
		servicedException: string;
		subject: string;
	}

}
export namespace BBG.Transfer.SendMailJob.Command {
	export interface CreateSendEmailJobCommand  {
		body: string;
		mailAddressTo: string;
		subject: string;
	}

	export interface UpdateSendEmailJobCommand  {
		body: string;
		mailAddressTo: string;
		subject: string;
	}

}
export namespace BBG.Transfer.Report {
	export interface ReportYachtBookingDto  {
		bookingId: number;
		bookingStatus: BBG.Core.BookingStatus;
		companyName: string;
		dateFrom: string;
		dateTo: string;
		firstName: string;
		lastName: string;
		yachtName: string;
	}

	export interface ReportYachtClassDto  {
		dateFrom: string;
		dateTo: string;
		yachtClasses: Array<BBG.Transfer.Report.ReportYachtClassValueDto>;
	}

	export interface ReportYachtClassValueDto  {
		value: number;
		yachtClassId: number;
		yachtClassName: string;
	}

	export interface ReportYachtDto  {
		dateFrom: string;
		dateTo: string;
		yachtClass: string;
		yachtClassId: number;
		yachts: Array<BBG.Transfer.Report.ReportYachtValueDto>;
	}

	export interface ReportYachtValueDto  {
		value: number;
		yachtId: number;
		yachtName: string;
	}

}
export namespace BBG.Transfer.Report.Query {
	export interface GenerateYachtBookingReportQuery extends BBG.Transfer.TimeRangeListQuery {
	}

	export interface GenerateYachtClassReportQuery  {
		endDate?: string;
		startDate?: string;
		yachtClassIds: number[];
	}

	export interface GenerateYachtReportQuery  {
		endDate?: string;
		startDate?: string;
		yachtClassId: number;
	}

}
export namespace BBG.Transfer.Payment {
	export interface PaymentAdminDto extends BBG.Transfer.Payment.PaymentDto {
		internalInfo: string;
	}

	export interface PaymentDto  {
		bookingId: number;
		createdAt: string;
		id: number;
		paymentType: BBG.Core.PaymentType;
		payUOrderId: string;
		status: BBG.Core.PaymentStatus;
		value: number;
	}

	export interface PayuPaymentCreateResultDto  {
		paymentId: number;
		redirectUrl: string;
	}

}
export namespace BBG.Transfer.Payment.Query {
	export interface ListPaymentQuery extends BBG.Transfer.ListQuery {
		bookingId?: number;
	}

}
export namespace BBG.Transfer.Payment.Command {
	export interface CreatePaymentAdminCommand extends BBG.Transfer.Payment.Command.CreatePaymentCommand {
		createdAt: string;
		internalInfo: string;
		paymentType: BBG.Core.PaymentType;
		value: number;
	}

	export interface CreatePaymentCommand  {
		bookingId: number;
	}

	export interface PayUNotificationCommand  {
		order: PayUPayment.PayUTransfer.PayUOrder;
	}

	export interface UpdatePaymentAdminCommand  {
		createdAt: string;
		internalInfo: string;
		paymentType: BBG.Core.PaymentType;
		status: BBG.Core.PaymentStatus;
		value: number;
	}

}
export namespace PayUPayment.PayUTransfer {
	export interface PayUBuyer  {
		customerId: string;
		delivery: PayUPayment.PayUTransfer.PayUBuyerDelivery;
		email: string;
		extCustomerId: string;
		firstName: string;
		language: string;
		lastName: string;
		nin: string;
		phone: string;
	}

	export interface PayUBuyerDelivery  {
		city: string;
		countryCode: string;
		name: string;
		postalBox: string;
		postalCode: string;
		recipientEmail: string;
		recipientName: string;
		recipientPhone: string;
		state: string;
		street: string;
	}

	export interface PayUOrder extends PayUPayment.PayUTransfer.PayUOrderBase {
		orderCreateDate: string;
		orderId: string;
		status: string;
	}

	export interface PayUOrderBase  {
		additionalDescription: string;
		buyer: PayUPayment.PayUTransfer.PayUBuyer;
		currencyCode: string;
		customerIp: string;
		description: string;
		extOrderId: string;
		merchantPosId: string;
		notifyUrl: string;
		products: Array<PayUPayment.PayUTransfer.PayUProduct>;
		totalAmount: string;
		validityTime: string;
	}

	export interface PayUProduct  {
		listingDate: string;
		name: string;
		quantity: string;
		unitPrice: string;
		virtual: string;
	}

}
export namespace BBG.Transfer.Invoice {
	export interface InvoiceDto  {
		addressCity: string;
		addressCountry: string;
		addressPostalCode: string;
		addressStreet: string;
		bookingId: number;
		companyName: string;
		externalCode: string;
		externalId?: number;
		id: number;
		nip?: number;
		userName: string;
		userSurname: string;
	}

}
export namespace BBG.Transfer.Invoice.Query {
	export interface ListInvoiceQuery extends BBG.Transfer.ListQuery {
	}

}
export namespace BBG.Transfer.Invoice.Command {
	export interface CreateInvoiceCommand  {
		addressCity: string;
		addressCountry: string;
		addressPostalCode: string;
		addressStreet: string;
		bookingId: number;
		companyName: string;
		nip?: number;
		userName: string;
		userSurname: string;
	}

	export interface UpdateInvoiceCommand  {
		addressCity: string;
		addressCountry: string;
		addressPostalCode: string;
		addressStreet: string;
		companyName: string;
		nip?: number;
		userName: string;
		userSurname: string;
	}

}
export namespace BBG.Transfer.Invoice.Api {
	export interface InvoiceDataDto  {
		buyerCity: string;
		buyerCompany: boolean;
		buyerCountry: string;
		buyerFirstName: string;
		buyerLastName: string;
		buyerName: string;
		buyerPostCode: string;
		buyerStreet: string;
		buyerTaxNo: string;
		discountKind: string;
		oid: number;
		paid: number;
		paymentTo: string;
		paymentType: string;
		positions: BBG.Transfer.Invoice.Api.InvoiceDataPositionDto[];
		sellerBank: string;
		sellerBankAccount: string;
		sellerCity: string;
		sellerEmail: string;
		sellerName: string;
		sellerPhone: string;
		sellerPostCode: string;
		sellerStreet: string;
		sellerTaxNo: string;
		showDiscount: boolean;
	}

	export interface InvoiceDataJsonHolderDto  {
		apiToken: string;
		invoice: BBG.Transfer.Invoice.Api.InvoiceDataDto;
	}

	export interface InvoiceDataPositionDto  {
		_destroy?: number;
		discount?: number;
		id?: number;
		name: string;
		quantity?: number;
		tax?: number;
		totalPriceGross?: number;
	}

	export interface InvoiceIdDto  {
		id: number;
		number: string;
	}

}
export namespace BBG.Transfer.Gps {
	export interface GpsDataDto  {
		azimuth?: number;
		dateTime: string;
		gpsId: number;
		ignition?: boolean;
		lat: number;
		lng: number;
		speed?: number;
	}

	export interface GpsDto  {
		id: number;
		lastUpdate?: string;
		name: string;
		yachtId?: number;
	}

	export interface YachtCurrentGpsDataDto  {
		data: BBG.Transfer.Gps.GpsDataDto;
		yachtId?: number;
		yachtName: string;
	}

	export interface YachtHistoricalGpsDataDto  {
		data: BBG.Transfer.Gps.GpsDataDto[];
		yachtId?: number;
		yachtName: string;
	}

}
export namespace BBG.Transfer.Gps.Query {
	export interface GetHistoricalGpsDataQuery  {
		dateFrom: string;
		dateTo: string;
	}

	export interface ListCurrentGpsDataQuery  {
		gpsIds: number[];
	}

	export interface ListGpsQuery  {
		gpsIds: number[];
	}

	export interface ListHistoricalGpsDataQuery  {
		dateFrom: string;
		dateTo: string;
		gpsIds: number[];
	}

}
export namespace BBG.Transfer.Gps.Api {
	export interface GpsCoordinateDto  {
		latitude: number;
		longitude: number;
	}

	export interface GpsCoordinateParam  {
		lat: number;
		lng: number;
	}

	export interface GpsDateTimeDto  {
		day: number;
		hour: number;
		minute: number;
		month: number;
		seconds: number;
		timezone: string;
		year: number;
	}

	export interface GpsDeviceDto  {
		deviceId: number;
		deviceName: string;
	}

	export interface GpsDevicesListDto  {
		deviceList: BBG.Transfer.Gps.Api.GpsDeviceDto[];
	}

	export interface GpsHistoricalBooleanParam extends BBG.Transfer.Gps.Api.GpsHistoricalParamDto {
		v: boolean;
	}

	export interface GpsHistoricalCoordinateParam extends BBG.Transfer.Gps.Api.GpsHistoricalParamDto {
		v: BBG.Transfer.Gps.Api.GpsCoordinateParam;
	}

	export interface GpsHistoricalDoubleParam extends BBG.Transfer.Gps.Api.GpsHistoricalParamDto {
		v: number;
	}

	export interface GpsHistoricalLongParam extends BBG.Transfer.Gps.Api.GpsHistoricalParamDto {
		v: number;
	}

	export interface GpsHistoricalParamDto  {
		p: string;
		ts: number;
		v: any;
	}

	export interface GpsParam<T>  {
		ts: number;
		v: T;
	}

	export interface GpsPositionDto  {
		coordinate: BBG.Transfer.Gps.Api.GpsCoordinateDto;
		dateTime: BBG.Transfer.Gps.Api.GpsDateTimeDto;
		deviceId: number;
		heading: number;
		ignitionState: string;
		speed: number;
	}

	export interface GpsPositionsListDto  {
		positionList: BBG.Transfer.Gps.Api.GpsPositionDto[];
	}

	export interface GpsSnapshotParams  {
		az: BBG.Transfer.Gps.Api.GpsParam<number>;
		c: BBG.Transfer.Gps.Api.GpsParam<BBG.Transfer.Gps.Api.GpsCoordinateParam>;
		i: BBG.Transfer.Gps.Api.GpsParam<boolean>;
		s: BBG.Transfer.Gps.Api.GpsParam<number>;
	}

	export interface GpsTrackingObjectDto  {
		currentSnapshot: BBG.Transfer.Gps.Api.GpsTrackingObjectSnapshotDto;
		name: string;
		trackingObjectId: number;
	}

	export interface GpsTrackingObjectSnapshotDto  {
		d: BBG.Transfer.Gps.Api.GpsSnapshotParams;
		ts: number;
	}

}
export namespace BBG.Transfer.ExternalProvider {
	export interface ExternalProviderDto  {
		oAuthUrl: string;
		type: BBG.Core.ExternalProviderType;
	}

	export interface UserExternalProviderDto extends BBG.Transfer.ExternalProvider.ExternalProviderDto {
		isConnected: boolean;
	}

}
export namespace BBG.Transfer.ExternalProvider.Command {
	export interface ExternalCodeCommand  {
		code: string;
		provider: BBG.Core.ExternalProviderType;
	}

}
export namespace BBG.Transfer.BookingSpec {
	export interface BookingSpecAdminBasicDto extends BBG.Transfer.BookingSpec.BookingSpecBasicDto {
		internalInfo: string;
		value?: number;
	}

	export interface BookingSpecAdminDto extends BBG.Transfer.BookingSpec.BookingSpecAdminBasicDto {
		yachtName: string;
	}

	export interface BookingSpecBasicDto  {
		bookingId?: number;
		dateFrom: string;
		dateTo: string;
		id?: number;
		yachtId: number;
	}

	export interface BookingSpecDto extends BBG.Transfer.BookingSpec.BookingSpecBasicDto {
		value: number;
		yachtClassName: string;
		yachtName: string;
	}

}
export namespace BBG.Transfer.BookingSpec.Query {
	export interface ListBookingSpecQuery extends BBG.Transfer.ListQuery {
		bookingId?: number;
		userId: string;
		yachtId?: number;
	}

}
export namespace BBG.Transfer.BookingSpec.Command {
	export interface CreateBookingSpecCommand  {
		bookingId: number;
		dateFrom: string;
		dateTo: string;
		internalInfo: string;
		yachtId: number;
	}

	export interface UpdateBookingSpecCommand extends BBG.Transfer.BookingSpec.Command.CreateBookingSpecCommand {
	}

}
export namespace BBG.Transfer.BookingHistory {
	export interface BookingHistoryDto  {
		bookingId: number;
		bookingStatus: BBG.Core.BookingStatus;
		createdAt: string;
		discount: number;
		id: number;
		value: number;
		valuePaid: number;
	}

}
export namespace BBG.Transfer.BookingHistory.Query {
	export interface ListBookingHistoryQuery extends BBG.Transfer.ListQuery {
		bookingIds: number[];
	}

}
export namespace BBG.Transfer.Booking {
	export interface BookingAdminDto extends BBG.Transfer.Booking.BookingBasicDto {
		additionalInfo: string;
		bookingSpecs: Array<BBG.Transfer.BookingSpec.BookingSpecAdminDto>;
		internalInfo: string;
		invoice: BBG.Transfer.Invoice.InvoiceDto;
		valuePaid: number;
		valueToBePaid: number;
	}

	export interface BookingBasicDto  {
		bookingStatus: BBG.Core.BookingStatus;
		createdAt: string;
		dateFrom: string;
		dateTo: string;
		discount: number;
		id: number;
		invoiceCode: string;
		isInvoiceRequested: boolean;
		userEmail: string;
		userId: string;
		value: number;
		valueWithDiscount: number;
		yachtsAmount: number;
	}

	export interface BookingDto extends BBG.Transfer.Booking.BookingBasicDto {
		additionalInfo: string;
		bookingSpecs: Array<BBG.Transfer.BookingSpec.BookingSpecDto>;
		canCreatePayUPayment: boolean;
		invoice: BBG.Transfer.Invoice.InvoiceDto;
		valuePaid: number;
		valueToBePaid: number;
	}

}
export namespace BBG.Transfer.Booking.Query {
	export interface ListBookingAdminQuery extends BBG.Transfer.Booking.Query.ListBookingQuery {
		statuses: number[];
		userId: string;
		withInvoicesToBeIssued: boolean;
		withIssuedInvoices: boolean;
	}

	export interface ListBookingQuery extends BBG.Transfer.TimeRangeListQuery {
		yachtId?: number;
	}

}
export namespace BBG.Transfer.Booking.Command {
	export interface CreateBookingAdminCommand  {
		additionalInfo: string;
		bookingSpecs: Array<BBG.Transfer.Booking.Command.CreateBookingSpecInBookingAdminCommand>;
		discount: number;
		internalInfo: string;
		invoice: BBG.Transfer.Booking.Command.CreateInvoiceInBookingCommand;
		status: BBG.Core.BookingStatus;
		userEmail: string;
	}

	export interface CreateBookingCommand  {
		additionalInfo: string;
		bookingSpecs: Array<BBG.Transfer.Booking.Command.CreateBookingSpecInBookingCommand>;
		invoice: BBG.Transfer.Booking.Command.CreateInvoiceInBookingCommand;
	}

	export interface CreateBookingInternalCommand extends BBG.Transfer.Booking.Command.CreateBookingCommand {
		userId: string;
	}

	export interface CreateBookingSpecInBookingAdminCommand extends BBG.Transfer.Booking.Command.CreateBookingSpecInBookingCommand {
		internalInfo: string;
	}

	export interface CreateBookingSpecInBookingCommand  {
		dateFrom: string;
		dateTo: string;
		yachtId: number;
	}

	export interface CreateInvoiceInBookingCommand  {
		addressCity: string;
		addressCountry: string;
		addressPostalCode: string;
		addressStreet: string;
		companyName: string;
		nip?: number;
		userName: string;
		userSurname: string;
	}

	export interface UpdateBookingAdminCommand  {
		additionalInfo: string;
		bookingSpecs: Array<BBG.Transfer.Booking.Command.CreateBookingSpecInBookingAdminCommand>;
		bookingStatus: BBG.Core.BookingStatus;
		discount: number;
		internalInfo: string;
		invoice: BBG.Transfer.Booking.Command.UpdateInvoiceInBookingCommand;
		userEmail: string;
	}

	export interface UpdateBookingCommand  {
		additionalInfo: string;
	}

	export interface UpdateBookingStatusCommand  {
		bookingStatus: BBG.Core.BookingStatus;
	}

	export interface UpdateInvoiceInBookingCommand extends BBG.Transfer.Booking.Command.CreateInvoiceInBookingCommand {
	}

}
export namespace BBG.Transfer.Auth.Command {
	export interface AuthorizeCommand  {
		email: string;
		password: string;
	}

}
export namespace PayUPayment.Config {
	export interface PayUConfiguration  {
		clientId: number;
		clientSecret: string;
		environment: string;
		grantType: string;
		merchantPosId: number;
		signatureKey: string;
	}

}
