
import { NgModule } from '@angular/core';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {
  UnauthorizedInterceptor
 } from './interceptors/unauthorized.interceptor';
import { FixJwtInterceptor } from './interceptors/fix-jwt.interceptor';
import { tokenGetter } from './utils/token-manager';

let restServerPath = environment.apiServer;
restServerPath = restServerPath.slice(restServerPath.indexOf('//') + 2);
const restServerHost = restServerPath.slice(0, restServerPath.indexOf('/'));

export function jwtOptionsFactory() {

  return {
    tokenGetter: () => {
      return tokenGetter();
    },
    whitelistedDomains: [
      restServerHost
    ],
    blacklistedRoutes: [
      new RegExp(`https?:\/\/${restServerHost}\/globeczarter-api\/api\/auth.*`),
      /\.\/.*/
    ]
  };
}

@NgModule({
  providers: [
    JwtHelperService,
    {
      provide: JWT_OPTIONS,
      useFactory: jwtOptionsFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FixJwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true
    }
  ]
})
export class JwtHelperModule { }
