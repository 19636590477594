import { isNil } from 'lodash';

const rememberMeKey = 'auth.rememberMe';
const tokenKey = 'auth.accessToken';

export function tokenGetter(): string {
  if (isRememberMe()) {
    return localStorage.getItem(tokenKey);
  } else {
    return sessionStorage.getItem(tokenKey);
  }
}

export function setToken(token: string, rememberMe: boolean) {
  setRememberMe(rememberMe);
  if (rememberMe) {
    localStorage.setItem(tokenKey, token);
  } else {
    sessionStorage.setItem(tokenKey, token);
  }
}

export function clearToken() {

  localStorage.removeItem(rememberMeKey);
  localStorage.removeItem(tokenKey);
  sessionStorage.removeItem(tokenKey);

}

function clearRememberMe() {
  localStorage.removeItem(rememberMeKey);
}

function setRememberMe(rememberMe: boolean) {
  const rememberMeString = JSON.stringify(rememberMe);

  if (rememberMe) {
    localStorage.setItem(rememberMeKey, rememberMeString);
  }
}

function isRememberMe(): boolean {
  const rememberMeString = localStorage.getItem(rememberMeKey);
  if (isNil(rememberMeString)) {
    return false;
  }

  const rememberMe = JSON.parse(rememberMeString);

  return rememberMe;
}
