import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NlModule } from 'nl';

const IMPORTS = [
  CommonModule,
  ReactiveFormsModule,
  FormsModule,
  TranslateModule,
  NgbModule,
  FontAwesomeModule,
  NlModule
];

const DIRECTIVES = [
];

const ENTRY_COMPONENTS = [
];

const COMPONENTS = [
];

const CONTROLS = [
];

const PIPES = [
];


const DECLARATIONS = [
  ...DIRECTIVES,
  ...ENTRY_COMPONENTS,
  ...COMPONENTS,
  ...CONTROLS,
  ...PIPES
];

@NgModule({
  imports: [
    ...IMPORTS,
  ],
  declarations: [
    ...DECLARATIONS
  ],
  entryComponents: [
    ...ENTRY_COMPONENTS
  ],
  exports: [
    ...IMPORTS,
    ...DECLARATIONS
  ]
})
export class SharedModule { }
