import { Injectable } from '@angular/core';
import {
  HttpRequestService
} from 'src/app/core/services/http-request/http-request.service';
import { BBG } from 'src/app/core/models/server-dto';
import TransferAuth = BBG.Transfer.Auth;

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {

  static API_PREFIX = 'api/auth';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  token(command: TransferAuth.Command.AuthorizeCommand) {
    return this.httpRequest.post<BBG.Transfer.AuthTokensDto>(
      `${AuthApiService.API_PREFIX}/token`,
      command
    );
  }

  externalProvider(command: BBG.Transfer.ExternalProvider.Command.ExternalCodeCommand) {
    return this.httpRequest.post<BBG.Transfer.AuthTokensDto>(
      `${AuthApiService.API_PREFIX}/external-provider`,
      command
    );
  }
}
