import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CanActiveAdmin } from "./guards/can-active-admin";

const routes: Routes = [
  {
    path: "",
    loadChildren: "./../page/page.module#PageModule",
  },

  {
    path: "admin",
    loadChildren: "./../admin/admin.module#AdminModule",
    canActivate: [CanActiveAdmin],
  },
  {
    path: "**",
    redirectTo: "",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: "enabled",
    }),
  ],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
