import { Injectable } from '@angular/core';
import { HttpRequestService } from 'src/app/core/services/http-request/http-request.service';
import Account = BBG.Transfer.Account;
import Command = Account.Command;
import { BBG } from 'src/app/core/models/server-dto';
@Injectable({
  providedIn: 'root'
})
export class AccountService {

  static API_PREFIX = 'api/account';

  constructor(
    private httpRequest: HttpRequestService
  ) { }

  register(command: Command.RegisterCommand) {
    return this.httpRequest.post<BBG.Transfer.User.UserDto>(
      `${AccountService.API_PREFIX}/register`,
      command
    );
  }

  sendActivationToken(command: Command.SendConfirmationTokenCommand) {
    return this.httpRequest.post<null>(
      `${AccountService.API_PREFIX}/send-confirmation-token`,
      command
    );
  }

  confirmEmail(command: Command.ConfirmCommand) {
    return this.httpRequest.post<null>(
      `${AccountService.API_PREFIX}/confirm-email`,
      command
    );
  }

  sendRestartPasswordToken(command: Command.RestartPasswordTokenCommand) {
    return this.httpRequest.post<null>(
      `${AccountService.API_PREFIX}/restart-password-token`,
      command
    );
  }

  restartPassword(command: Command.RestartPasswordCommand) {
    return this.httpRequest.post<null>(
      `${AccountService.API_PREFIX}/restart-password`,
      command
    );
  }

  setPassword(command: Command.SetPasswordCommand) {
    return this.httpRequest.post<null>(
      `${AccountService.API_PREFIX}/set-password`,
      command
    );
  }

  changePassword(command: Command.ChangePasswordCommand) {
    return this.httpRequest.put<null>(
      `${AccountService.API_PREFIX}/change-password`,
      command
    );
  }

  me() {
    return this.httpRequest.get<BBG.Transfer.User.UserDto>(
      `${AccountService.API_PREFIX}/get-my-information`,
    );
  }

  updateProfile(command: Command.UpdateProfileCommand) {
    return this.httpRequest.put<BBG.Transfer.User.UserDto>(
      `${AccountService.API_PREFIX}/update-profile`,
      command
    );
  }

  updateCulture(culture: BBG.Core.CultureName) {
    return this.httpRequest.put<any>(
      `${AccountService.API_PREFIX}/set-culture/${culture}`,
      null
    )
  }

  disconnectWithExternalProvider(command: Command.DisconnectExternalProviderCommand) {
    return this.httpRequest.put<null>(
      `${AccountService.API_PREFIX}/disconnect-external-provider`,
      command
    );
  }

  connectWithExternalProvider(command: BBG.Transfer.ExternalProvider.Command.ExternalCodeCommand) {
    return this.httpRequest.post<null>(
      `${AccountService.API_PREFIX}/connect-with-external-account`,
      command
    );
  }
}
