import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { AuthService } from '../../../auth/services/auth.service';
import { AccountService } from '../../../page/sign/services/account/account.service';
import { switchMap, map, filter, catchError, concatMap } from 'rxjs/operators';
import { isNil } from 'lodash';
import { BBG } from '../../models/server-dto';
import { titleCase } from 'change-case';
import { of, Observable, BehaviorSubject, combineLatest, Subscription } from 'rxjs';

const LSLanguageKey = '__view_language__';

@Injectable({
  providedIn: 'root'
})
export class TranslateHelperService {

  static defaultLang = 'en-GB';

  private cultureSubject = new BehaviorSubject<BBG.Core.CultureName>(null);
  private subUserChange: Subscription;

  constructor(
    private translateService: TranslateService,
    private authService: AuthService,
    private accountService: AccountService,
  ) {

    this.translateService
      .onLangChange
      .subscribe(event => {
        this.cultureSubject.next(event.lang);
        moment.locale(event.lang);
        this.storedLang = event.lang;
      });

    this.translateService.setDefaultLang(TranslateHelperService.defaultLang);
    this.translateService.use(this.storedLang);

    this.fallowUserAuth();

  }

  changeCulture(culture: BBG.Core.CultureName) {
    const cultureMap = {
      [BBG.Core.CultureName.Pl]: 'pl-PL',
      [BBG.Core.CultureName.En]: 'en-GB',
      [BBG.Core.CultureName.De]: 'de-DE',
      [BBG.Core.CultureName.Fr]: 'fr-FR'
    }

    let lang;

    if (isNil(culture) || isNil(cultureMap[culture])) {
      lang = this.storedLang;
    } else {
      lang = cultureMap[culture];
    }
    this.translateService.use(lang);
  }

  get currentCulture(): BBG.Core.CultureName {
    return BBG.Core.CultureName[titleCase(this.translateService.currentLang.split('-')[0])];
  }

  get culture$(): Observable<BBG.Core.CultureName> {
    return this.cultureSubject.pipe(map(() => this.currentCulture));
  }

  get storedLang(): string {
    const lSLanguage = localStorage.getItem(LSLanguageKey);
    if (!isNil(lSLanguage)) {
      return lSLanguage
    }
    return 'pl-PL';
  }

  set storedLang(storedLang: string) {
    localStorage.setItem(LSLanguageKey, storedLang);
  }

  private fallowUserAuth() {
    const auth$ = this.authService.isAuthorized();
    auth$.pipe(
      switchMap(isAuth => {
        if (!isAuth) {
          return of(null);
        } else {
          return this.accountService.me()
        }
      })
    )
      .subscribe(user => {
        if (isNil(user)) {
          this.changeCulture(null);
          if(this.subUserChange){
            this.subUserChange.unsubscribe();
          }
          this.subUserChange =  null;
        } else {
          this.changeCulture(user.data.culture);
          this.changeUserCultureIfItIsLogin();
        }
      })
  }

  private changeUserCultureIfItIsLogin() {
    this.subUserChange = combineLatest(this.authService.isAuthorized(), this.culture$)
      .pipe(
        filter(([isAuth, culture]) => {
          return isAuth;
        }),
        map(([isAuth, culture]) => culture),
        switchMap((culture) => {
          return this.accountService.updateCulture(culture)
        }),
        catchError((error) => {
          return of();
        })
      ).subscribe();

  }

}
