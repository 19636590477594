import {
  NgModule, Optional, SkipSelf, ModuleWithProviders
} from '@angular/core';

import {
  TranslateModule,
  TranslateLoader
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { CoreRoutingModule } from './core-routing.module';
import { AppComponent } from './containers/app/app.component';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from '../shared/shared.module';
import { AuthModule } from '../auth/auth.module';
import 'moment/locale/pl';
import { CanActiveAdmin } from './guards/can-active-admin';
import { OPTIONAL_INTERCEPTORS, AcceptLanguageHeaderInterceptor } from 'nl';
import { TranslateHelperService } from './services/translate-helper/translate-helper.service';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    SharedModule,
    CoreRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AuthModule
  ],
  providers: [
    CanActiveAdmin,
    ...OPTIONAL_INTERCEPTORS,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AcceptLanguageHeaderInterceptor,
      multi: true
    }
  ],
  declarations: [
    AppComponent
  ]
})
export class CoreModule {

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
    translateHelperService: TranslateHelperService
  ) {
    if (parentModule) {
      throw new Error('Moduł główny już wczytany.');
    }
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule
    };
  }
}
