import { NgModule } from '@angular/core';
import { JwtHelperModule } from './jwt-helper.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    JwtHelperModule
  ]
})
export class AuthModule { }
