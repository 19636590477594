import { Injectable } from '@angular/core';
import { BBG } from 'src/app/core/models/server-dto';

import TransferAuth = BBG.Transfer.Auth;
import { Observable, BehaviorSubject } from 'rxjs';
import { tap, distinctUntilChanged } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { clearToken, setToken } from '../utils/token-manager';
import { AuthApiService } from './auth-api/auth-api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  private authSubject = new BehaviorSubject(false);
  constructor(
    private jwtHelper: JwtHelperService,
    private authApiService: AuthApiService
  ) {
    this.authSubject.next(!this.isTokenExpire());
  }

  login(command: TransferAuth.Command.AuthorizeCommand, isRememberMe: boolean = true) {
    return this.authApiService.token(command).pipe(
      tap(token => {
        this.authorize(token.data.jwtToken.token, isRememberMe);
      })
    );
  }

  loginByExternal(command: BBG.Transfer.ExternalProvider.Command.ExternalCodeCommand, isRememberMe: boolean = true) {
    return this.authApiService.externalProvider(command).pipe(
      tap(token => {
        this.authorize(token.data.jwtToken.token, isRememberMe);
      })
    );
  }

  isAuthorized(): Observable<boolean> {
    return this.authSubject.asObservable().pipe(
      distinctUntilChanged()
    );
  }

  getTokenExpirationDate(): Date {
    return this.jwtHelper.getTokenExpirationDate();
  }

  getDecodedToken(): any {
    return this.jwtHelper.decodeToken();
  }

  logout() {
    clearToken();
    this.authSubject.next(false);
  }

  isAdmin(): boolean {
    try {
      return !this.isTokenExpire() && JSON.parse(this.jwtHelper.decodeToken().app_isAdmin);
    } catch (error) {
      return false;
    }

  }

  private authorize(token: string, isRememberMe: boolean) {
    setToken(token, isRememberMe);
    this.authSubject.next(true);
  }

  private isTokenExpire(): boolean {
    const token = this.jwtHelper.tokenGetter();
    return this.jwtHelper.isTokenExpired(token);
  }

}
