// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export let serverHost = location.hostname;
const overrideHost = localStorage.getItem("serverHost");
const protocol = location.protocol;

if (
  overrideHost !== undefined &&
  overrideHost !== null &&
  overrideHost !== ""
) {
  serverHost = overrideHost;
}

export const environment = {
  production: false,
  apiServer: `https://netland.com.pl/profertoczarter-api/`,
  startOfISOWeek: 6,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
